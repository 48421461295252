import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { UpLoadFile, FirstImageField } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
//import ResetOrderNum from './Brand_ResetOrderNum';

{/* 
  import { BrandList, BrandCreate, BrandEdit, BrandShow } from './components/Brand';

  <Resource name="CMSBrand" list={BrandList} create={BrandCreate} edit={BrandEdit} show={BrandShow} />
  <Resource name="CMSBrand" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: 'Brand',
  },
  table: {
    id: 'id',
    Name: '品牌名称',
    Banners: '品牌Banner',
    HasPicture: '图片模块',
    HasVideo: '视频模块',
    OrderNum: '排序',
    UpTime: '更新时间',
  }
};

//分页列表页面
export const BrandList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        {/* <ShowButton {...props} /><br /> */}
        <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <TextField label={useTxtData.table.Name} source="Name" />
        {/* <TextField label={useTxtData.table.Banners} source="Banners" /> */}
        <FirstImageField label={useTxtData.table.Banners} source="Banners" className={classes.imageField}  aliPicResize='?x-oss-process=image/resize,w_100,m_lfit' />
        <BooleanField label={useTxtData.table.HasPicture} source="HasPicture" />
        <BooleanField label={useTxtData.table.HasVideo} source="HasVideo" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const BrandShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <TextField label={useTxtData.table.Name} source="Name" />
        {/* <TextField label={useTxtData.table.Banners} source="Banners" /> */}
        <FirstImageField label={useTxtData.table.Banners} source="Banners" />
        <BooleanField label={useTxtData.table.HasPicture} source="HasPicture" />
        <BooleanField label={useTxtData.table.HasVideo} source="HasVideo" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const BrandEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const BrandCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      <TextInput label={useTxtData.table.Name} source="Name" validate={[required()]} />
      {/* <TextInput label={useTxtData.table.Banners} source="Banners" validate={[required()]} /> */}
      <UpLoadFile multiple={true} label={useTxtData.table.Banners} source="Banners" validate={[required()]} />
      <BooleanInput label={useTxtData.table.HasPicture} source="HasPicture" validate={[required()]} />
      <BooleanInput label={useTxtData.table.HasVideo} source="HasVideo" validate={[required()]} />
      <NumberInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} defaultValue={0} />
      {/* <TextInput label={useTxtData.table.UpTime} source="UpTime" validate={[required()]} /> */}
    </SimpleForm>
  );
}