import * as React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from 'ra-language-chinese';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import MyLayout from './customLayout/layout';
import LoginPage from './loginPage';
import Dashboard from './dashboardPage';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CategoryIcon from '@material-ui/icons/Category';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SortIcon from '@material-ui/icons/Sort';
import MessageIcon from '@material-ui/icons/Message';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import VideocamIcon from '@material-ui/icons/Videocam';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';

import ChangePwd from './customLayout/ChangePwd';

import { AdminList, AdminCreate, AdminEdit } from './components/Admin';
import { BrandList, BrandCreate, BrandEdit, BrandShow } from './components/Brand';
import { PictureSortList, PictureSortCreate, PictureSortEdit, PictureSortShow } from './components/PictureSort';
import { PictureList, PictureCreate, PictureEdit, PictureShow } from './components/Picture';
import { VideoList, VideoCreate, VideoEdit, VideoShow } from './components/Video';
import { SHOW_PictureList, SHOW_PictureCreate, SHOW_PictureEdit, SHOW_PictureShow } from './components/SHOW_Picture';
import { SHOW_VideoList, SHOW_VideoCreate, SHOW_VideoEdit, SHOW_VideoShow } from './components/SHOW_Video';

//补充缺失的翻译内容 一下是增加一个confirm的中文翻译
chinese.ra.action = { ...chinese.ra.action, confirm: '确认' };
//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh');

const customRoutes = [
  <Route exact path="/ChangePwd" component={ChangePwd} />,
];

const App = () => (
  <Admin
    title="后台管理"
    layout={MyLayout}
    customRoutes={customRoutes}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
  >

    <Resource options={{ group: '设置', label: '管理员' }} name="Admin/CMSAdmin" list={AdminList} create={AdminCreate} edit={AdminEdit} icon={<SupervisorAccountIcon />} />
    <Resource options={{ group: '品牌', label: '品牌' }} name="Admin/CMSBrand" list={BrandList} create={BrandCreate} edit={BrandEdit} icon={<FontDownloadIcon />} />
    <Resource options={{ group: '品牌', label: '分类' }} name="Admin/CMSPictureSort" list={PictureSortList} create={PictureSortCreate} edit={PictureSortEdit} icon={<PhotoFilterIcon />} />
    <Resource options={{ group: '图片', label: '图片' }} name="Admin/CMSPicture" list={PictureList} create={PictureCreate} edit={PictureEdit} icon={<PhotoLibraryIcon />} />
    <Resource options={{ group: '图片', label: '买家秀' }} name="Admin/CMSSHOW_Picture" list={SHOW_PictureList} create={SHOW_PictureCreate} edit={SHOW_PictureEdit} icon={<CameraEnhanceIcon />} />

    <Resource options={{ group: '买家秀/视频', label: '视频' }} name="Admin/CMSVideo" list={VideoList} create={VideoCreate} edit={VideoEdit} icon={<VideocamIcon />} />
    <Resource options={{ group: '买家秀/视频', label: '买家秀' }} name="Admin/CMSSHOW_Video" list={SHOW_VideoList} create={SHOW_VideoCreate} edit={SHOW_VideoEdit} icon={<MovieFilterIcon />} />
  </Admin>
);

export default App;