import { apiUrl } from './global';

const authProvider = {
    login: ({ username, password }) => {
        return fetch(apiUrl + '/User/AdminLogin', {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            body: `username=${username}&password=${password}`
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((result) => {
                if (result.Code === 1) {
                    console.log('login success');
                    localStorage.setItem('token', result.Result.Token);
                }
                else {
                    console.log('login fail');
                    throw new Error(result.Message);
                }
            });
    },
    logout: () => {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('token');
            resolve();
        });
        // return fetch(apiUrl + '/User/AdminSignOut', {
        //     method: 'POST',
        //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
        //     body: `token=${localStorage.getItem('token')}`
        // })
        //     .then(response => {
        //         if (response.status < 200 || response.status >= 300) {
        //             throw new Error(response.statusText);
        //         }
        //         return response.json();
        //     })
        //     .then((result) => {
        //         if (result.Code === 1) {
        //             console.log('logout success');
        //             localStorage.removeItem('token');
        //         }
        //         else {
        //             console.log('logout fail');
        //             throw new Error(result.Message);
        //         }
        //     });
    },
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject(),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
};

export default authProvider;